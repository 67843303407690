import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AnimatedPlaceholder from './components/AnimatedPlaceholder.vue'
import ImageBlock from './components/ImageBlock.vue'
import ImageBlockSkeleton from './components/ImageBlockSkeleton.vue'

createApp(App)
	.component('AnimatedPlaceholder', AnimatedPlaceholder)
	.component('ImageBlock', ImageBlock)
	.component('ImageBlockSkeleton', ImageBlockSkeleton)
	.use(store)
	.use(router)
	.mount('#app')