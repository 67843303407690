<template>
  <div id="home" class="page">
    <Suspense>
      <ImageBlock :imgFileName="this.recentWork"/>
      <template #fallback>
        <ImageBlockSkeleton class-name="home" width="100%" />
      </template>
    </Suspense>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  computed: {
    recentWork() {
      return this.$store.state.works[0].url
    }
  }
}
</script>

<style lang="scss" scoped>
#home {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: $space-20;

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;

    @include min-width(wide) {
      margin: $space-20 $space-40;
      max-width: 700px;
    }
  }
}
</style>