<template>
	<div :class="computedClass">
		&nbsp;
	</div>
</template>

<script>
export default {
	props: {
		className: {
			type: String,
			required: true,
			validator: (value) => ['grid', 'home', 'item', 'profile'].includes(value)
		},
		width: {
			type: String,
			required: true
		}
	},
	computed: {
		computedClass() {
			return `placeholder placeholder__${this.className}`
		}
	}
}
</script>

<style lang="scss" scoped>
@keyframes bgAnimate {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -150% 0;
  }
}

.placeholder {
  animation: bgAnimate 1.2s linear infinite;
	background-image: linear-gradient(
		to right,
		#dedad3 0%,
		#f1eee7 10%,
		#dedad3 20%,
		#dedad3 100%,
		);
	background-size: 200% 100%;
	width: v-bind(width);

// WorksAll
	&__grid {
		height: 185px;

		@include min-width(small) {
			height: 415px;
		}

		@include min-width(medium) {
			height: 350px;
		}

		@include min-width(wide) {
			height: 180px;
		}

		@include min-width(extrawide) {
			height: 285px;
		}
	}

// HomePage
	&__home {
		height: 380px;

		@include min-width(xsmall) {
			height: 400px;
		}

		@include min-width(small) {
			height: 740px;
		}

		@include min-width(medium) {
			height: 475px;
		}

		@include min-width(wide) {
			height: 835px;
		}

		@include min-width(extrawide) {
			height: 915;
		}
	}

// WorkItem
	&__item {
		height: 380px;

		@include min-width(xsmall) {
			height: 400px;
		}

		@include min-width(small) {
			height: 740px;
		}

		@include min-width(medium) {
			height: 240px;
		}

		@include min-width(wide) {
			height: 500px;
		}
	}

// AboutMe
	&__profile {
		height: 445px;

		@include min-width(xsmall) {
			height: 500px;
		}

		@include min-width(small) {
			height: 600px;
		}

		@include min-width(medium) {
			height: 480px;
		}

		@include min-width(wide) {
			height: 600px;
		}
	}
}
</style>