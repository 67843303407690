<template>
  <nav>
    <router-link to="/works" exactActiveClass="active-path">Portfolio</router-link>
    <router-link to="/about" exactActiveClass="active-path">About</router-link>
    <router-link to="/contact" exactActiveClass="active-path">Contact</router-link>
  </nav>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style lang="scss" scoped>
nav {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  order: 2;
  padding: $space-20;
  position: relative;
  text-align: center;

  @include min-width(medium) {
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
    text-align: right;
    order: 3;
  }

  a {
    min-height: 23px;
    min-width: 65px;
    font-size: 16px;

    & + a {
      margin: 0 0 0 16px;

      @include min-width(medium) {
        margin: 4px 0 0 0;
      }
    }

    &.active-path {
      font-family: $font-apercu__medium--italic;
    }
  }
}
</style>