<template>
  <div class="masthead">
    <router-link to="/" class="name">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 244 538"><path d="M0 269v269l121.8-.2 121.7-.3V.5L121.8.2 0 0zM212 51.5V94h-85.9l-8.8-8.8c-9.9-9.9-15.7-13.4-26.9-16.2-20.2-5.2-39.5.1-54.5 15C24.3 95.5 19 108.3 19 124.5c0 30.6 23.3 55.3 54 57.2 16.4 1 30.4-4.3 43-16.3l7.7-7.4H212v93h9V9h14v520H9v-72h95v60h8v-60h91v-8H9V9h203zM97.4 80.8c30.2 15 36.8 54.1 13.1 77.8-19.3 19.5-49.9 19.3-69.2-.3-24.9-25.3-14.5-68.1 19.5-80 5.8-2.1 8.1-2.3 17.4-2 10.3.3 11.1.5 19.2 4.5M212 126v23h-83.6l2.3-6.9c4-12.1 4-22.3-.3-36.9l-.6-2.2H212z"/><path d="M122.1 196.5c-20.5 5.8-34.9 19.3-41.8 39.2-2.2 6.5-2.7 9.5-2.7 18.3 0 17.1 5.3 30.1 17.3 42 11.6 11.4 25.3 17.1 41.6 17.1 16.6 0 30.1-5.7 42-17.6 22.1-22.1 23.1-55.7 2.3-80.7-5-6-16.7-13.9-24.9-16.8-8.4-2.9-25.9-3.7-33.8-1.5m36.2 12.3c9.6 4.7 18.9 13.6 23.2 22.2 9.8 19.4 6.1 43.4-9.2 58.7-6 6-11.5 9.4-19.9 12.4-7.9 2.9-22.6 3.1-30.4.5-25.5-8.5-39.9-32.6-35-58.7 2.5-13.3 13.4-27.7 25.9-34.3 9.3-4.9 12.7-5.6 25.8-5.2 10.9.2 11.4.3 19.6 4.4M61 319.6c-15.4 4-30.9 16.7-37.4 30.5-4.7 9.8-6.6 17.7-6.6 26.9 0 32.2 26.8 59 59 59 32.7-.1 59.1-26.2 59.2-58.5.1-5.4-.6-11.6-1.6-15.5-5.5-20.6-22-37-42.6-42.5-7-1.9-22.8-1.8-30 .1m25.1 7.8c38.2 8.1 53.5 53.9 27.8 83-16 18.1-44.4 22.1-64.9 9.1-10.1-6.4-19.3-19.2-21.9-30.5-1.7-7.5-1.3-20.1.9-27.3 6.3-20.4 26-34.9 48-35.6 1.9 0 6.5.6 10.1 1.3"/></svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style lang="scss" scoped>
.masthead {
  order: 1;
  padding: $space-20 $space-20 0 $space-20;
  text-align: center;

  @include min-width(medium) {
    padding: $space-20 calc(#{$space-20}*4) $space-20 $space-20;
    text-align: left;
  }

  svg {
    width: 42px;

    @include min-width(medium) {
      width: 60px;
    }        
  }
}
</style>