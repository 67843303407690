import { createStore } from 'vuex'

export default createStore({
  state: {
    works: [{
      id: 'layali',
      name: 'Layali',
      url: 'layali_8Z68axaYe',
      year: 2025,
      medium: 'Acrylic and wax on canvas',
      size: [36, 48],
      landscape: false,
      available: true
      },
      {
      id: 'the-sleep-of-the-shadow',
      name: 'The Sleep of the Shadow',
      url: 'the-sleep-of-the-shadow_Z79hrRka8',
      year: 2025,
      medium: 'Acrylic and wax on canvas',
      size: [30, 40],
      landscape: false,
      available: true
      },
      {
      id: 'between-the-sown-fields-and-meadows',
      name: 'Between the Sown Fields and Meadows',
      url: 'between-the-sown-fields-and-meadows_xVulPNuLj',
      year: 2025,
      medium: 'Acrylic, wax pencil, oil pastel, and hanji on canvas',
      size: [24, 36],
      landscape: false,
      available: false
      },
      {
      id: 'where-the-mountains',
      name: 'Where the Mountains Close In',
      url: 'where-the-mountains-close-in_3dHWDC7P3',
      year: 2024,
      medium: 'Acrylic, wax pencil, soft pastel, and origami paper on canvas',
      size: [30, 40],
      landscape: false,
      available: true
      },
      {
      id: 'zamana',
      name: 'Zamana',
      url: 'zamana_237agLhxy',
      year: 2024,
      medium: 'Acrylic, ceramic stucco, wax pencil, soft pastel, charcoal, origami paper, and newsprint on canvas',
      size: [36, 48],
      landscape: false,
      available: false
      },
      {
      id: 'nora',
      name: 'Nora',
      url: 'nora_mq_6Vu1QE',
      year: 2024,
      medium: 'Acrylic, ceramic stucco, wax pencil, and soft pastel on canvas',
      size: [36, 36],
      landscape: false,
      available: false
      },
      {
      id: 'plastic-blooms',
      name: 'Plastic Blooms',
      url: 'plastic-blooms_HxLkgs3lo',
      year: 2024,
      medium: 'Acrylic, wax pencil, sand, pastel, and origami paper on canvas',
      size: [30, 40],
      landscape: false,
      available: true
      },
      {
      id: 'holding-court',
      name: 'Holding Court',
      url: 'holding-court_0NmlHIM1X',
      year: 2024,
      medium: 'Acrylic, wax pencil, pastel, and origami paper on canvas',
      size: [24, 36],
      landscape: false,
      available: true
      },
      {
      id: 'lynis',
      name: 'Lynis',
      url: 'lynis_axLbpj18f',
      year: 2024,
      medium: 'Acrylic and wax pencil on canvas',
      size: [24, 36],
      landscape: false,
      available: false
      },
      {
      id: 'sunder',
      name: 'Sunder',
      url: 'sunder_Z__lThOds',
      year: 2024,
      medium: 'Acrylic, wax pencil, and soft pastel on canvas',
      size: [36, 36],
      landscape: false,
      available: false
      },
      {
      id: 'bada-boda',
      name: 'Bada Boda',
      url: 'bada-boda_wZITZpi78',
      year: 2024,
      medium: 'Acrylic, wax pencil, and soft pastel on canvas',
      size: [36, 36],
      landscape: false,
      available: false
      },
      {
      id: 'one-for-yes-two-for-no',
      name: 'One for yes, two for no',
      url: 'one-for-yes-two-for-no_ciDyZOmHE',
      year: 2024,
      medium: 'Acrylic, wax pencil, and soft pastel on canvas',
      size: [30, 40],
      landscape: false,
      available: false
      },
      {
      id: 'smoulder-waves',
      name: 'Smoulder waves',
      url: 'smoulder-waves_2aTEVvP8y',
      year: 2024,
      medium: 'Mixed media on canvas',
      size: [30, 40],
      landscape: false,
      available: false
      },
      {
      id: 'insomnie',
      name: 'Insomnie',
      url: 'insomnie_rcLaahd0zb',
      year: 2023,
      medium: 'Acrylic, wax pencil, and charcoal on canvas',
      size: [24, 36],
      landscape: false,
      available: false
      },
      {
      id: 'persimmon-punch',
      name: 'Persimmon Punch',
      url: 'persimmon-punch_UmkQcK3_x',
      year: 2023,
      medium: 'Acrylic, soft pastel, and wax pencil on canvas',
      size: [24, 24],
      landscape: false,
      available: false
      },
      {
      id: 'antonio',
      name: 'Antonio',
      url: 'antonio_qw0N362DA4',
      year: 2023,
      medium: 'Mixed media on canvas',
      size: [30, 40],
      landscape: false,
      available: false
      },
      {
      id: 'collars',
      name: 'Collars',
      url: 'collars_f-TxvWNCOc',
      year: 2023,
      medium: 'Acrylic, soft pastel, and wax pencil on canvas',
      size: [24, 36],
      landscape: false,
      available: false
      },
      {
      id: 'v-as-in-victor',
      name: 'V as in Victor',
      url: 'v-as-in-victor_fXjfbh_sI',
      year: 2022,
      medium: 'Mixed media on canvas',
      size: [18, 24],
      landscape: false,
      available: false
      },
      {
      id: 'wolfgang',
      name: 'Wolfgang',
      url: 'wolfgang_bkXmvb8qj',
      year: 2022,
      medium: 'Acrylic and graphite on canvas',
      size: [30, 40],
      landscape: false,
      available: false
      },
      {
      id: 'ooljima-iii',
      name: 'Ooljima III',
      url: 'ooljima-3_O7nhGl7LfU',
      year: 2022,
      medium: 'Acrylic on canvas',
      size: [18, 24],
      landscape: false,
      available: false
      }]
  }
})
