<template>
	<AnimatedPlaceholder :class-name="className" :width="width"/>
</template>

<script>
export default {
	props: {
		className: String,
		width: String
	}
}
</script>