<template>
	<img :src="this.imgUrl"/>
</template>

<script>
export default {
	name: 'ImageBlock',
	props: {
		imgFileName: String
	},
	async setup(props) {
		const imageUrl = `https://ik.imagekit.io/9sjnvniyw/${props.imgFileName}.webp`;
		const img = new Image();
		img.src = imageUrl;
		await img.decode();

		return {};
	},
  computed: {
    imgUrl() {
      return `https://ik.imagekit.io/9sjnvniyw/${this.imgFileName}.webp`
    }
  }
}
</script>